import React from "react"

import cn from "classnames"

import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"

import css from "./Pills.module.scss"

export interface PillProps {
  isRefined: boolean
  icon?: string
  onclick?: React.MouseEventHandler<HTMLDivElement>
  mainColor: string
  disabled?: boolean
  children: React.ReactNode
}

export function Pills({
  isRefined,
  icon,
  onclick,
  mainColor,
  disabled,
  children,
}: PillProps) {
  return (
    <div
      className={cn(css.pills, {
        [css.refined]: isRefined,
        [css.disabled]: disabled,
      })}
      onClick={disabled ? undefined : onclick}
      style={{
        backgroundColor: isRefined ? mainColor : "transparent",
      }}
    >
      {icon && (
        <ColorableSVG
          className={cn(
            css.logoChefclub,
            disabled ? css.disabledIcon : undefined
          )}
          href={icon} //TODO : refactor when migration icons will be merged
          mainColor={mainColor}
          width="20"
          height="20"
          title="Chefclub"
        />
      )}
      {children}
    </div>
  )
}
